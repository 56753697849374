<template>
  <div class="black--text d-flex wrapper h100 vh100 pa-50 center">
    <div class="tos">
      <TOS />
    </div>
  </div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import TOS from "@/components/Auth/Register/Terms.vue";
export default {
  components: {
    TopToolbar,
    TOS,
  },
};
</script>

<style scoped>
.tos {
  max-width: 976px;
  width: 100%;
  box-sizing: border-box;
  padding: 70px;
  background-color: white;
}

.wrapper {
  background-color: #525659;
}

/deep/ a {
  color: #0645ad;
}

@media only screen and (max-width: 576px) {
  .wrapper {
    padding: 0px;
  }

  .tos {
    padding: 10px;
  }
}
</style>